#pay-layout{
    overflow: hidden;
    background-color: white;
    transition: .4s;
    padding: 48px;
}

#pay-layout>div{
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
}

#pay-layout>div>.ant-row{
    width: 100%;
    margin: 30px 0;
    padding: 30px 0;
    border-top: 1px solid #baccd9;
}

#text button{
    width: 90px;
    height: 40px;
    justify-content: center;
    align-items: center;
}

.ant-descriptions-view{
    background-color: #ffffff;
    box-shadow: 0 0 10px 3px #baccd9;
}

.ant-descriptions-view table th{
    font-size: 1.4em;
   text-align: center;
}

.ant-descriptions-view table td{
    font-size: 1.3em;
    letter-spacing:1px;
   text-align: center;
}