#help{
    padding: 50px;
    box-shadow: 0 0 0 20px #d8dde9 inset;
}

#help > h1{
    font-size: 36px;
}

#help .ant-tabs-tab{
    font-size: 24px;
    padding: 10px 20px;
}

#help .ant-collapse-header{
    font-size: 22px;
    font-weight: bold;
}

#help .ant-collapse-content-box p{
    text-align: start;
    font-size: 18px;
}

#help .ant-collapse-content-box .focus-block{
    padding: 2px;
    border: 2px solid #87cfff;
    background-color: #daf0ff;
}

#help .ant-collapse-content-box .focus-element{
    color: #0070bb;
    font-weight: bold;
}

#help .ant-collapse-content-box .focus-warning{
    color: #e5e900;
    font-weight: bold;
}

#help .ant-collapse-content-box .focus-start{
    /* color: #89ff7e; */
    background-color: #b8ffb1;
}

#help .ant-collapse-content-box .focus-end{
    /* color: #89ff7e; */
    background-color: #f0ffad;
}