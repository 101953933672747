.carousel-content{
    height: 550px;
}

.course-card{
    font-size: 1.3em;
    overflow: hidden;
    border-radius: 20px;
    margin: 30px;
    background-color: #ffffff;
    box-shadow: 2px 2px 10px 0px #cecece;
}

.course-card:hover>div:first-child::before{
    border: 1px solid #c4c4c4;
    transform: scale(.9);
}

.course-card:hover img{
    transform: scale(1.1);
}

.course-card>div:first-child{
    width: 100%;
    cursor: pointer;
    transition: .3s;
    overflow: hidden;
    position: relative;
    
}

.course-card>div:first-child::before{
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 20px 20px 0 0;
    transition: .3s;
    z-index: 3;
}

.course-card img{
    width: 100%;
    cursor: pointer;
    transition: .3s;
}

.course-card>div:last-child{
    position: relative;
    height: 200px;
    display: flex;
    flex-flow:column nowrap;
    padding: 30px 40px 20px 40px;
    align-items: flex-start;
    justify-content: space-around;
}

.course-card>div>.course-sub-name{
    font-size: 1.5em;
}

.course-card>div>.course-card-footer{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.course-card-footer>div{
    font-size: 1.1em;
    color: #868686;
}

.course-card-footer .iconfont{
    margin-right:5px;
    color: #0091ff;
    font-size: .9em;
}

.course-card>div>.course-cate-name{
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%,-35%);
    background-color:#0091ff;
    color: #ffffff;
    width: 50%;
    height: 40px;
    line-height: 40px;
}

/* .ant-layout-content{
    padding:0 3%;
} */