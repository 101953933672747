#user-course-info{
    width: 100%;
    font-size: large;
    padding: 40px;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
}

.user-course-detail{
    width: 35%;
    min-width: 300px;
    padding: 20px;
}

.user-course-detail>div{
    margin: 10px 0;
    box-shadow: 3px 3px 6px #c0ecff;
    border-radius: 10px;
}

.user-course-detail .ant-card-head-title{
    font-size: 24px;
    color: #474747;
    text-shadow:1px 1px 5px #d4d4d4;
    letter-spacing: 5px;
}

.user-course-detail .ant-card-body>div:nth-child(2){
    margin: 10px auto;
    padding-top: 10px;
    border-top: 1px solid #d4d4d4;
}

.user-course-detail .ant-card-body>div:nth-child(3){
    font-size: 1.6em;
    margin: 10px auto;
}

.user-course-detail .ant-card-body>div:nth-child(3) span{
    font-size: 1.4em;
    margin: 0 3px;
    font-weight: bold;
    color: #00b3ff;
    text-shadow:1px 1px 35px #00b3ff;
}


.user-course-detail >.course-total{
    background-color: #ffffff;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
    height: 60px;
    align-items: center;
}

.course-total span{
    font-size: 1.4em;
    margin: 0 3px;
    font-weight: bold;
    color: #00b3ff;
}

.user-course-list{
    flex: 1;
    margin: 30px;
}

.user-course-list>.user-course-card{
    border: 1px solid #00b3ff;
    margin: 0 20px 40px 20px;
    cursor: pointer;
    padding: 20px;
    border-radius: 10px;
    background-color: #cbe0e9;
    transition: .3s;
}

.user-course-card>h2{
    text-align: start;
    text-shadow:3px 3px 5px #ffffff;
}

.user-course-card>div{
    display: flex;
    justify-content: space-between;
}

.user-course-card>.user-course-progress{
    flex-direction: column;
    margin: 15px 0;
}

.user-course-progress>div{
    text-align: start;
}

.user-course-card>div:last-child{
    font-weight: bold;
    text-shadow:3px 3px 5px #ffffff;
    color:#00293a
}

.user-course-list>.user-course-card:hover{
    box-shadow: 3px 3px 5px 1px #00293a;
}

.lesson-page{
    position: relative;
    width: 100%;
}

.lesson-page > button{
    position: absolute;
    right: 0;
    top: 0;
    height: 40px;
    border: none;
    background-color: #ffffff;
    width: 98px;
    outline: none;
    z-index: 3;
    cursor: pointer;
}

.lesson-page > .ant-tabs>div{
    margin: 0;
}

.ant-tabs>.ant-tabs-content-holder{
    padding: 10px;
    background-color: #ffffff;
}

.lesson-empty{
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.lesson-info h1{
    font-size: 1.5em;
    margin: 10px 0;
    padding:10px 0;
    border-top:2px solid #d6d6d6 ;
    transition-property: border;
    transition-duration:.6s;
}

.lesson-info>div:hover h1{
    border-color: #00b3ff;
}

.lesson-info>.lesson-info-detail>div{
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
}
.lesson-info>.lesson-info-detail>div>div{
    position: relative;
    margin: 10px 30px;
    width: 220px;
    background-color: #d9f4ff;
    height: 108px;
    font-size: large;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #006188;
}

.lesson-info>.lesson-info-detail>div>div:nth-child(2n+1)>h3{
    margin: 0;
}

.lesson-info>.lesson-info-detail .iconfont{
    position: absolute;
    right: 4px;
    top: 0;
    font-size: 1.2em;
    cursor: pointer;
    color: #00b3ff;
}

#confirm-window{
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    z-index: 4;
    background-color: #f0f0f0;
    height: 70vh;
    width: 60vw;
    box-shadow: 0 0 10px 2px #a0a0a0;
}

#confirm-window::-webkit-scrollbar{
    display: none;
}

#confirm-window div{
    display: flex;
}

#confirm-window>div:nth-child(1){
    height: 25%;
    background-color: #ffffff;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
}

#confirm-window>div:nth-child(1)>span{
    position: absolute;
    color: #f4ff5c;
    font-size: 2.5em;
    background-color: transparent;
    left: 10px;
    border: none;
    top:10px;
}
#confirm-window>div:nth-child(1)>p{
    margin: 10px;
}

#confirm-window>div:nth-child(2){
    overflow: hidden;
    height: 15%;
    justify-content: flex-start;
    border-bottom: 2px solid #ffffff;
    align-items: center;
    overflow-x: auto;
    padding: 0 20px;
}

#confirm-window>div:nth-child(3){
    height: 45%;
    overflow: auto;
    justify-content: space-around;
    flex-flow: row wrap;
}

#confirm-window>div:nth-child(4){
    height: 15%;
    justify-content: space-around;
    align-items: center;
    background-color: #ffffff;
}

#confirm-window>div:nth-child(3)>span{
    width: 280px;
}

#confirm-window>div:nth-child(4)>div{
    width: 180px;
    background-color: #ffffff;
    justify-content: center;
    height: 50px;
    align-items: center;
    margin: 20px;
    border-radius: 20px;
    cursor: pointer;
}

#confirm-window>div:nth-child(4)>div:last-child{
    background-color: #74e3ff;
}

#confirm-window>div:nth-child(4)>div:first-child{
    background-color: #d4ff5d;
}

#confirm-window>div>span{
    height: 50px;
    padding: 0 20px;
    line-height: 50px;
    border: 1px solid #00b3ff;
    background-color: #ffffff;
    margin: 15px;
    border-radius: 34px;
    cursor: pointer;
    white-space: nowrap;
}

.commnet-board{
    width: 60%;
    margin: 0 auto;
    padding: 10px 20px 20px 20px;
    font-size: large;
    box-shadow: 0 0 5px 0 #c7c7c7;
}