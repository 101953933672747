#timetable{
    background-color: #ffffff;
    box-shadow: 0 0 6px 0 #bdbdbd;
    padding: 10px;
    --inblock_border:1px solid #b1b1b1;
    --odd_bgcolor:#ecf8ff;
    --time_rate : calc(100%/16);
}

#timetable div{
    display: flex;
    align-items: center;
    /* background-color: #e2e2e2; */
}

#timetable>h1{
    font-size: 2em;
    font-weight: 600;
}

#timetable > .timetable-content{
    flex-flow: row nowrap;
    height: 720px;
}

#timetable > .timetable-content{
    border: var(--inblock_border);
    border-bottom: none;
}

.timetable-content> .time-scale{
    /* background-color: #01a7e9; */
    font-weight: 600;
    flex-flow: column nowrap;
    height: 100%;
    justify-content: space-around;
    width: 86px;
}

.time-scale>span{
    border-bottom:var(--inblock_border);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: var(--time_rate);
}

.time-scale>span:last-child{
    flex: 1;
}

.time-scale>span:nth-child(2n+1){
    background-color: var(--odd_bgcolor);
}

.timetable-content > .timetable-header{
    width: 100%;
    height: 64px;
    font-size: 1.2em;
    font-weight: 550;
    flex-flow: row nowrap;
    justify-content: space-around;
}

.timetable-header > span{
    width: 10%;
    min-width: 88px;
}

.timetable-content > .timetable-body{
    flex: 1;
    flex-flow: column wrap;
    justify-content: space-around;
    height: 100%;
}

.timetable-body > .time-block{
    position: relative;
    height: var(--time_rate);
    width: calc(100%/7);
    border-bottom: var(--inblock_border);
    border-left: var(--inblock_border);
    min-width: 88px;
    cursor: pointer;
    flex-flow: column nowrap;
}

.time-block>div{
    width: 100%;
    height: 50%;
}

.block-hover>div:hover{
    /* background-color: #e4e4e4; */
    box-shadow: 0 0 5px 3px #e4e4e4 inset;
}

.timetable-body > .time-block:nth-child(2n+1){
    background-color: var(--odd_bgcolor);
}

.timetable-body > .time-block:nth-child(16n){
    flex: 1;
}

.timetable-body .time-conflict{
    box-shadow: 0 0 5px 5px #ec989e inset;
}

.timetable-body .time-block-selected{
    background-image: linear-gradient(45deg,#c8efff 5%,#64d0ff 80%);
}

.timetable-body .time-block-taken{
    background-image: linear-gradient(135deg,#c9c9c9 10%,#757575 80%);
}

.timetable-body .time-block-picked{
    background-image: linear-gradient(135deg,#eaffe3 5%,#79ff84 80%);
}
/* 
.time-block>.time-block-none{
    position: absolute;
    height: 100%;
} */

.time-block .lesson-block{
    flex: 1;
    height: 100%;
    position: relative;
    flex-flow: flex nowrap;
    justify-content: center;
    position: relative;
    box-shadow: 0 0 3px 2px #bbdbff inset;
}

.time-block .block-two{
    background-color: #d0f2ff;
}

.block-two::after{
    content: attr(data-duration);
    box-shadow: 0 0 3px 2px #bbdbff inset;
    width: 100%;
    height: 100%;
    background-color: #d0f2ff;
    position: absolute;
    top: 100%;
    z-index: 4;
    font-weight: 500;
    font-size: 1.2em;
}

.lesson-block>span:first-child{
    font-weight: 600;
    font-size: 1.2em;
}

.tool-tips{
    padding: 5px 10px;
    font-size: 1.2em;
}

.setup-area{
    position: relative;
    justify-content: space-between;
    align-items: center;
    padding: 100px 0 30px 0;   
}

.setup-area>h3{
    font-family: Helvetica;
    font-weight: bold;
    font-size: 2em;
    position: absolute;
    top: 0;
    left: 50%;
    width: 100%;
    height: 56px;
    border-bottom: var(--inblock_border);
    transform: translateX(-50%);
}

.setup-content{
    flex-flow: row wrap;
}

.setup-area button{
    padding: 10px 30px;
    margin: 0 20px;
    border-radius: 6px;
    outline: none;
    background-color: #006a97;
    border: none;
    color: #d5f2ff;
    cursor: pointer;
}

.setup-area span{
    position: relative;
    display: inline-block;
    margin: 20px 15px;
    font-family: "Oswald",sans-serif;
    text-transform: uppercase;
    text-shadow:1px 1px 5px #bebebe;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
}

.setup-area span:hover::before{
    content:attr(data-des);
    position: absolute;
    display: flex;
    justify-content: flex-start;
    background-color: white;
    box-shadow: 0 0 5px 0 #64d0ff;
    min-width:20px;
    padding: 10px;
    font-size: 1.2em;
    white-space: nowrap;
    border-radius: 20px 0 20px 0;
    animation: showdes .5s forwards;
    left: 15px;
    color: black;
}

.setup-area .teachers-tag:hover::before{
    background-color: #dee9ee;
    color: #01a7e9;
}

@keyframes showdes{
    from{
        transform: translateY(0);
        opacity: 0;
    }to{
        opacity: 1;
        transform: translateY(-110%);
    }
}

.progress-bar{
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
    color: #01a7e9;
    cursor: default;
    font-weight: 600;
}

.progress-bar>.progress-bar-content{
    width: calc(100%/3);
}

.progress-bar>.progress-bar-content>div:first-child{
    font-size: 1.6em;
    border: 2px solid #006a97;
    color: #006a97;
    width: 80px;
    height: 80px;
    margin:0 auto 10px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}
