#q_server{
    background-color: #ffffff;
    width: 100%;
    padding: 5%;
    min-height: 40vh;
    /* display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: flex-start; */
}
#q_server > h1{
    font-size: 1.5em;
    font-weight: bold;
}

#q_server .q_card{
    width: 30%;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 7px;
    border: 2px solid #003aa5;
    cursor: pointer;
    font-size: 1.2em;
}

#q_server .q_select{
    background-color: #003aa5;
    color: #ffffff;
}

#q_server img{
    width: 100px;
    height: 100px;
}
