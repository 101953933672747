*{
    margin:0;
    padding:0;
    text-align: center;
}

html {
    overflow-y: overlay;
}

a{
    text-decoration: none;
}

#locator{
    background-color: rgba(163,163,163,0.5);
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index:99;
}

#locator>#alert-message{
    position: absolute;
    perspective: 1px;
    overflow: hidden;
    left: 50%;
    top: 40px;
    width: 30%;
    min-width: 360px;
    border-style: solid;
    transform: translateX(-50%);
    z-index: 99;
    box-sizing: border-box;
    box-shadow: 4px 4px 9px 0px #7e7e7e;
}

#alert-message .iconfont{
    position: fixed;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 2.5em;
}

.description{
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    /* padding: 50px; */
    justify-content: flex-start;
}

.description div{
    display: flex;
}

.description>.des-card{
    margin: 20px 0;
    width: 100%;
    flex-flow: column nowrap;
    justify-content: flex-start;
}

.des-card h2{
    color: #0091ff;
    position: relative;
    font-size: 1.5em;
    text-align: start;
}

.des-card p{
    text-align: start;
    margin:5px 0 10px 0;
    font-size: 1.2em;
}

.description > .des-card:hover > h2::before{
    content: '';
    position: absolute;
    height: 2px;
    background-color: #0091ff;
    animation: lineGrow .5s forwards;
}

.info-bar{
    height: 60px;
    margin-bottom: 20px;
    line-height: 60px;
    font-size: 1.2em;
    font-weight: 600;
    color: #3b3b3b;
    background-image: linear-gradient(90deg,#ddffe9, #c2ffd6, #80d9ff);
    cursor: pointer;
}

.info-bar-new{
    animation: alertBack 1s infinite ease-in-out;
}

@keyframes alertBack{
    0%{
        box-shadow: 0px 0px 5px 0px #73ff00;
    }
    50%{
        box-shadow: 0px 0px 5px 3px #73ff00;
    }
    100%{
        box-shadow: 0px 0px 5px 0px #73ff00;
    }
}

#message-notifier{
    width: 50px;
    height: 50px;
    position: fixed;
    z-index: 99;
    right: 20px;
    top: 10px;
    cursor: pointer;
    animation: notifier 1.4s infinite ease-in-out;
    transition: .3s;
    border-radius: 50%;
    background-color: white;
    box-shadow: inset 0px 0px 10px 1px #fffb00;
}

.message-card{
    font-size: 1.1em;
    font-weight: 600;
    padding-bottom: 5px;
    display: flex;
    flex-flow: column wrap;
    align-items: flex-start;
    justify-content: space-around;
    /* background-image: linear-gradient(90deg,#ddffe9, #c2ffd6, #d3f2ff); */
    /* height: 8vh; */
    border-bottom:1px solid #7e7e7e ;
    margin-bottom: 10px;
    /* box-shadow: 1px 1px 4px 0px #999999; */
    cursor: pointer;
}

.message-card:hover{
    border-bottom:none;
    box-shadow: 0 1px 5px 0px #999999;
}

@keyframes notifier{
    0%{
        transform: translateY(0);
    }
    50%{
        transform: translateY(-6px);
    }
    100%{
        transform: translateY(0);
    }
}

@keyframes lineGrow{
    from{
        width: 0%;
        top: 200%;
        opacity: 0;
    }to{
        width: 100%;
        top: 120%;
        opacity: 1;
    }
}

