#categories>h1{
    margin: 30px 0;
    letter-spacing: 10px;
}

.course-tag{
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    margin: 30px 50px;
    border-top: 1px solid #d3d3d3;
    font-family:Helvetica;
}

.course-tag > span{
    cursor: pointer;
    padding: 0px 15px;
    height: 50px;
    min-width: 120px;
    line-height: 48px;
    font-size: 1.2em;
}

.course-card{
    min-width: 220px;
}

.course-card img{
    max-width: 100%;
    max-height: 100%;
}

.course-tag > span:hover{
    color:#0091ff
}

#des{
    margin: 0 50px;
    background-color: #f1ffb4;
    border: 2px solid #e3ff67;
    border-radius: 20px 0;
    min-height: 130px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 1.6em;
    transition: .3s;
}

.transition-des-text-enter{
    opacity: 0;
    transform: translateY(-30%);
}
.transition-des-text-enter-active{
    opacity: 1;
    transform: translateY(0%);
}
.transition-des-text-exit{
    opacity: 1;
    transform: translateY(0%);
}
.transition-des-text-exit-active{
    opacity: 0;
    transform: translateY(-30%);
}

.transition-course-card-enter{
    opacity: 0;
    transform: scale(0.4);
}
.transition-course-card-enter-active{
    opacity: 1;
    transform: scale(1);
}
.transition-course-card-exit{
    opacity: 1;
    transform: scale(1);
}
.transition-course-card-exit-active{
    opacity: 0;
    transform: scale(0.4);
}

.transition-course-card-enter-active,
.transition-course-card-exit-active,
.transition-des-text-enter-active,
.transition-des-text-exit-active{
    transition: opacity .3s, transform .3s;
}

#des>p{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    text-align: start;
    padding: 0 20px;
    margin: 0;
}

#course-flow {
    padding: 40px;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center;
}

#course-flow .ant-card{
    transition: .4s;
}

#course-flow .ant-card:hover{
    transform: scale(1.1);
}