#teacher-summary .ant-row{
    margin:10px;
    font-family: Helvetica;
    font-size: 1.4em;
}

#teacher-summary .ant-row>.ant-col:first-child{
    font-weight: 600;
}
/* 


#teacher-summary .ant-row .ant-col-16{
    white-space: nowrap;
    display: flex;
    justify-content: flex-end;
} */