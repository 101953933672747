.login-form {
    min-width: 300px;
  }

.login-form-button {
  width: 100%;
}

input#normal_login_username.ant-input{
  text-align: left;
  padding-left: 10px;
}

input#normal_login_password.ant-input{
  text-align: left;
  padding-left: 10px;
}

label.ant-checkbox-wrapper{
  float:left;
}

a.login-form-forgot{
  float:right;
}

.left{
  float:left;
}

form#normal_login.ant-form.ant-form-horizontal.login-form{
  justify-content: center;
}

.center{
  text-align:center; 
  display:flex; 
  justify-content:center;
}

#register .ant-form-item-control-input{
  width: 450px;
}

#view-file{
  position: absolute;
  display: flex;
  justify-content: space-around;
  z-index: 99;
  width: 100vw;
  left: 0;
  top: 0%;
  background-color: #f6ffed;
  padding: 30px;
}

#view-file>.banner{
  margin: 30px 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

#view-file>.banner>button{
  margin: 20px 0;
}
